import React, { useState } from "react";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_URL } from "constants/api-constants";
import Mobile from "assets/svgs/contact-us/mobile.svg";
import Email from "assets/svgs/contact-us/mail.svg";
import Location from "assets/svgs/contact-us/location.svg";
import Globe from "assets/svgs/contact-us/globe.svg";
// import Circle from "assets/svgs/circle.svg";
// import CircleCheck from "assets/svgs/circle-check.svg";
import Button from "components/button";
import { showToast } from "helpers";
import { NOTIFICATION_STATUS_TYPES } from "constants/app-constant";
import Footer from "components/footer";
import "./contact.scss";

const LIST = [
	{
		label: "+918220337179",
		icon: Mobile,
	},
	{
		label: "info@mmttransport.in",
		icon: Email,
	},
	{
		label: "https://mmttransport.in",
		icon: Globe,
	},
	{
		label: `BO : Survey # 286/3, Rayakottah, Main Road, Onnalvadi, Hosur Taluk HOSUR • 635109.`,
		icon: Location,
	},
	{
		label:
			"HO : #43, Adithya Complex, Vallalar Nagar, Vilankurichi Road, Vilankurichi, COIMBATORE - 614035",
		icon: Location,
	},
];

// const SERVICES = [
// 	{
// 		label: "AI/ML",
// 		value: "AI/ML",
// 	},
// 	{
// 		label: "RPA",
// 		value: "RPA",
// 	},
// 	{
// 		label: "IoT",
// 		value: "IoT",
// 	},
// 	{
// 		label: "ERP",
// 		value: "ERP",
// 	},
// 	{
// 		label: "CMS",
// 		value: "CMS",
// 	},
// 	{
// 		label: "SEO",
// 		value: "SEO",
// 	},
// ];

// const SERVICES_TWO = [
// 	{
// 		label: "Full-Stack Engineering",
// 		value: "Full-Stack Engineering",
// 	},
// 	{
// 		label: "No-code Low-code",
// 		value: "No-code Low-code",
// 	},
// 	{
// 		label: "E-commerce solutions",
// 		value: "E-commerce solutions",
// 	},
// ];

export const Input = ({
	label,
	type = "text",
	placeholder,
	value,
	onChange,
	name,
	...rest
}) => {
	return (
		<div className="input">
			<label>{label}</label>
			<input
				type={type}
				value={value}
				onChange={onChange}
				name={name}
				{...{
					...(placeholder && {
						placeholder,
					}),
				}}
				{...rest}
			/>
		</div>
	);
};

const DEFAULT_STATE = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	message: "",
	service: [],
};

const ContactUs = () => {
	const [details, setDetails] = useState(DEFAULT_STATE);
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === "service") {
			setDetails((prevDetails) => {
				const updatedService = prevDetails.service.includes(value)
					? prevDetails.service.filter((item) => item !== value)
					: [...prevDetails.service, value];
				return {
					...prevDetails,
					service: updatedService,
				};
			});
		} else {
			setDetails((details) => ({
				...details,
				[name]: value,
			}));
		}
	};

	const handleSubmit = () => {
		setLoading(true);
		if (
			!details.firstName ||
			!details.lastName ||
			!details.email ||
			!details.phone ||
			details.service.length === 0 ||
			!details.message
		) {
			showToast(
				"Error",
				"Please fill in all fields",
				NOTIFICATION_STATUS_TYPES.ERROR
			);
			setLoading(false);
		} else {
			details.serviceNeeded = details.service.join(",");
			axios
				.post(API_URL.CONTACT, details)
				.then((res) => {
					showToast(
						"Success",
						`Thank you for reaching out to us, we're eager to collaborate with you`
					);
					setLoading(false);
					setDetails(DEFAULT_STATE);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	};
	console.log("🚀 ~ handleSubmit ~ handleSubmit:", handleSubmit);

	return (
		<Row
			className="contact_us"
			style={{
				paddingBottom: 0,
			}}
		>
			<Col sm={24} xl={24}>
				<Row
					justify={"center"}
					align={"middle"}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}
				>
					<Col sm={24} xl={16}>
						<h1 className="title">
							Connect <span>with us</span>
						</h1>
						<p className="desc">
							Engage with MMT for expert solutions and collaborative ventures.
							Reach out today to discuss your unique requirements and discover
							how our innovative talent pools can help elevate your business to
							unprecedented levels
						</p>
					</Col>
					<Col xl={20} sm={24}>
						<Row gutter={[20, 20]} className="input_area">
							<Col xl={9} className="left_area">
								<div className="input_container">
									<span className="contact_info">Contact Information</span>
									<p className="contact_desc">
										Fill up the form and our Team will get back to you within 24
										hours.
									</p>
									<ul className="links">
										{LIST.map((item, index) => {
											return (
												<li key={index}>
													<div className="icon_container">
														<img src={item.icon} alt="icon" />
													</div>
													<span>{item.label}</span>
												</li>
											);
										})}
									</ul>
								</div>
							</Col>
							<Col sm={24} xl={15} className="right_area">
								{loading ? (
									<div className="loader">
										<Spin
											spinning={loading}
											tip="Loading..."
											indicator={
												<LoadingOutlined
													style={{ fontSize: 40, color: "#3f4ff8" }}
													spin
												/>
											}
										/>
									</div>
								) : (
									<Row className="text_input_area" gutter={[20, 40]}>
										<Col sm={24} xl={12} className="input_area">
											<Input
												label="First Name"
												value={details.firstName}
												name="firstName"
												onChange={handleInputChange}
											/>
										</Col>
										<Col sm={24} xl={12} className="input_area">
											<Input
												label="Last Name"
												value={details.lastName}
												name="lastName"
												onChange={handleInputChange}
											/>
										</Col>
										<Col sm={24} xl={12} className="input_area">
											<Input
												label="Email id"
												value={details.email}
												name="email"
												onChange={handleInputChange}
											/>
										</Col>
										<Col sm={24} xl={12} className="input_area">
											<Input
												label="Phone"
												value={details.phone}
												name="phone"
												onChange={handleInputChange}
												maxLength={10}
											/>
										</Col>
										{/* <Col xl={24} className="what_service">
											<label className="label_style">What service do you need?</label>
											<ul className="service_selection">
												{SERVICES.map((item, index) => {
													return (
														<li
															key={index}
															className={details.service.includes(item?.value) ? 'selected' : ''}
															onClick={() => handleInputChange({ target: { name: 'service', value: item?.value } })}>
															<div className="check_container">
																<img src={details.service.includes(item?.value) ? CircleCheck : Circle} alt="icon" />
																<label htmlFor={item?.value}>{item?.label}</label>
															</div>
														</li>
													);
												})}
											</ul>
											<ul className="service_selection service_selection_3">
												{SERVICES_TWO.map((item, index) => {
													return (
														<li
															key={index}
															className={details.service.includes(item?.value) ? 'selected' : ''}
															onClick={() => handleInputChange({ target: { name: 'service', value: item?.value } })}>
															<div className="check_container">
																<img src={details.service.includes(item?.value) ? CircleCheck : Circle} alt="icon" />
																<label htmlFor={item?.value}>{item?.label}</label>
															</div>
														</li>
													);
												})}
											</ul>
										</Col> */}
										<Col sm={24} xl={24} className="input_area">
											<Input
												label="Message"
												placeholder="Write your message..."
												value={details.message}
												name="message"
												onChange={handleInputChange}
											/>
										</Col>
										<Col xl={24} className="button_container">
											<Button
											// onClick={handleSubmit}
											>
												Send Message
											</Button>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
					<Col xl={20} sm={24}>
						<Row>
							<Col
								style={{
									padding: 20,
									backgroundColor: "#000",
									borderRadius: 10,
									width: "50%",
								}}
							>
								<img
									style={{
										width: "100%",
									}}
									src={require("assets/images/map.jpeg")}
									alt="whatsapp"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col
				span={24}
				style={{
					paddingTop: 20,
				}}
			>
				<Footer />
			</Col>
		</Row>
	);
};

export default ContactUs;
